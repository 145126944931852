<template>
	<div id="app">
		<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover">
		<router-view />
	</div>
</template>

<script>
	import '@/assets/css/common.css'
	
	export default {
		name: 'App',
	}
</script>