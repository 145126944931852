import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import './plugins/axios.js'
import router from './plugins/router.js'

Vue.config.productionTip = false

document.title = '华博证券'

Vue.use(Vant)

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')